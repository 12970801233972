<template>
    <div class="page-container">
        <md-app>
            <app-toolbar></app-toolbar>
            <app-drawer></app-drawer>
            <md-app-content>
                <div class="md-layout admin">
                    <div class="md-layout-item md-size-100" v-if="!loading">
                        <div class="md-layout">
                            <div class="md-layout-item md-size-85">
                                <md-tabs>
                                    <md-tab id="summary" md-label="FEEDBACK">
                                        <md-card>
                                            <md-card-content>
                                                <div class="md-layout md-gutter md-size-100">
                                                    <div class="md-layout-item">
                                                        <md-field>

                                                            <md-textarea v-model="feedbackcopy" id="subscription-cancel-text" name="subscription-cancel-text" :placeholder="'We are sorry to see you leave, please provide us with any feedback so we can improve our service.'"></md-textarea>
                                                            <span class="md-error">Feedback content is required</span>
                                                        </md-field>
                                                    </div>
                                                </div>
                                            </md-card-content>
                                            <md-card-actions class="actions">
                                                <md-button
                                                        class="md-raised md-primary"
                                                        type="submit"
                                                        @click="openConfirmation = true;"
                                                        :disabled="feedbackcopy.length<3"
                                                >Cancel</md-button>
                                            </md-card-actions>
                                        </md-card>
                                        <md-snackbar
                                                md-position="center"
                                                :md-duration="Infinity"
                                                :md-active.sync="feedback"
                                                md-persistent
                                        >
                                            <span>Your subscription for {{ facility.attributes.name }} has been set to stop on {{subscriptionEndDate}}.</span>
                                            <md-button class="md-primary" @click="feedback = false">Close</md-button>
                                        </md-snackbar>
                                    </md-tab>
                                </md-tabs>
                            </div>
                        </div>
                    </div>
                    <md-progress-spinner md-mode="indeterminate" class="spinner" v-show="loading"></md-progress-spinner>

                    <md-dialog-confirm
                            :md-active.sync="openConfirmation"
                            md-title="Stop your subscription?"
                            :md-content="'Are you sure you want to stop your subscription? It will remain active until ' + subscriptionEndDate"
                            md-confirm-text="Agree"
                            md-cancel-text="Disagree"
                            @md-cancel="openConfirmation = false"
                            @md-confirm="cancelSubscriptionConfirmed"
                    />
                </div>
            </md-app-content>
        </md-app>
    </div>
</template>

<script lang="javascript">
    import config from '../../config.js'
    import { validationMixin } from 'vuelidate'
    import { mapGetters, mapActions } from 'vuex'
    import { required, minLength, maxLength } from 'vuelidate/lib/validators'
    import { format, getDate, getDaysInMonth, setDate, addMonths } from 'date-fns'

    export default{
        mixins: [validationMixin],
        name: 'SendFeedback',
        data(){
            return {
                facilityId: null,
                config: config,
                errors: null,
                users: [],
                loading: true,
                feedback: false,
                feedbackcopy: '',
                // facility: {},
                openConfirmation: false,
                subscriptionId: null,
            }
        },
        computed: {
            ...mapGetters([
                'facilities',
                'subscriptions'
            ]),
            subscription() {
                return this.subscriptions.find(subscription => subscription.id === this.subscriptionId)
            },
            facility() {
                const facility = this.facilities.find(facility => facility.attributes.facilityId === this.subscription.facilityId);
                // console.log('SUBSCRIPTION', subscription);
                // console.log('facility', facility);
                return facility
            },
            subscriptionEndDate() {
                const daysThisMonth = getDaysInMonth(new Date());
                const daysNextMonth = getDaysInMonth(addMonths(new Date(), 1));
                const subscriptionDateOfMonth = getDate(this.subscription.startDate);
                const todaysDateOfMonth = getDate(new Date());

                if(todaysDateOfMonth < subscriptionDateOfMonth){
                    if(subscriptionDateOfMonth <= daysThisMonth){
                        return format(setDate(new Date(), subscriptionDateOfMonth), 'MM/DD/YYYY')
                    } else {
                        return format(setDate(new Date(), daysThisMonth), 'MM/DD/YYYY')
                    }
                }else {
                    if(subscriptionDateOfMonth <= daysNextMonth){
                        return format(setDate(addMonths(new Date(), 1), subscriptionDateOfMonth), 'MM/DD/YYYY')
                    }
                    else {
                        return format(setDate(addMonths(new Date(), 1), daysNextMonth), 'MM/DD/YYYY')
                    }
                }
            }
        },
        async created(){
            this.users = this.$store.getters.user
            this.subscriptionId = parseInt(this.$route.params.subscriptionId)
            this.loading = false
        },
        mounted(){
        },
        methods: {
            ...mapActions(['cancelSubscription']),
            async cancelSubscriptionConfirmed(){
                // console.log('cancel subscription confirmed');
                await this.cancelSubscription(this.subscription.id);
                await this.submitFeedback()
            },
            submitFeedback()
            {
                var data = {
                    description: this.feedbackcopy,
                    email: this.users.email,
                    name: this.users.first_name + ' ' + this.users.last_name,
                    facilityId: this.facility.attributes.facilityId,
                    facility_name: this.facility.attributes.name

                }

                this.feedback = true
                this.feedbackcopy = []
            }
        }
    }

</script>

<style lang="scss" scoped>
    .error-message{
        color: red;
    }
    .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }
    .md-tab{
        padding: 0;

    }
    .actions {
        width: 598px;
        padding-top: 20px;
        .md-primary{
            margin-left: 10px;
        }
        padding-bottom: 30px;
    }
    .md-card{
        box-shadow: none;
    }
    .actions{
        width: 743px
    }
</style>